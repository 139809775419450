import React from "react";
import StandardFormComponent from "../../basics/form/StandardFormComponent";
import { withRouter } from 'react-router-dom';
import FormToastComponent from "../../basics/FormToastComponent";
import FormHelper from "../../../helpers/FormHelper";
import CheckPermissions from "../../../helpers/CheckPermissions";
import UserRolesEnum from "../../../enums/UserRolesEnum";
import Module from "../../../models/module/Module";
import ModuleRepository from "../../../repository/ModuleRepository";
import { LoaderComponent } from "../../basics/layout";
import EventPeriod from "../../../models/event/EventPeriod";
import PeriodTextFormComponent from "./PeriodTextFormComponent";
import { EventPeriodRepository } from "../../../repository";

class EventFormComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            model: this.props.model !== undefined ? this.props.model : new EventPeriod(),
            loading: true,
            settings: {},
            action: 'create',
            roles: this.props.user.roles,
        };

        this.table_ref = React.createRef();

        this.customProps = { ...this.props };
        this.isManager = CheckPermissions.role(this.state.roles, UserRolesEnum.MANAGER) && !CheckPermissions.role(this.state.roles, UserRolesEnum.RESELLER);
    }

    componentDidMount() {
        let promises = [
            ModuleRepository.getSettings()
        ];

        if (this.props.get_promise !== undefined) {
            promises.push(this.props.get_promise);
        }

        Promise.all(promises)
            .then(result => {
                let settings = result[0]?.data ? result[0]?.data.settings : {};

                let model = this.state.model;
                if (result[1] !== undefined) {
                    model = result[1];
                    let content = model.contents.filter(content => content.language === this.props.match.params.language)[0];
                    model.title = content.title;
                } else {
                    model.event = this.props.match.params.event;
                }

                let action = this.state.action;
                if (this.props.location.pathname.indexOf('/duplicate/') > -1) {
                    action = 'duplicate';

                    model.clone();
                } else if (model.id) {
                    action = 'edit';
                }

                this.setState({
                    loading: false,
                    settings: settings,
                    model: model,
                    action: action
                });
            });
    }

    success = (model) => {
        FormToastComponent.successTrans("Period", "Default.saved");
        this.props.history.push('/module/' + this.props.slug + '/' + Module.getId() + '/periods/' + model.event + '/overview');
    }

    error = (error) => FormHelper.handleSubmitError(this, error);

    render() {
        if (this.state.loading) return <LoaderComponent />;

        return (
            <StandardFormComponent
                {...this.customProps}
                ref={this.table_ref}
                parent={this}
                className="event-period"
                validationSchema={EventPeriod.schema(this.state.settings, this.state.model)}
                model={this.state.model}
                formSubmit={(values, formikActions) => {
                    values = { ...values, ...this.state.model.transform(this.state.settings) }

                    if (this.state.action === 'edit') {
                        EventPeriodRepository.update({ id: values.id, language: this.props.match.params.language, ...values })
                            .then(() => this.success(values))
                            .catch(error => this.error(error))
                    } else if (this.state.action === 'duplicate') {
                        values.id = this.props.match.params.id;
                        EventPeriodRepository.duplicate({ id: this.props.match.params.id, language: this.props.match.params.language, ...values })
                            .then(() => this.success(values))
                            .catch(error => this.error(error))
                    } else {
                        EventPeriodRepository.create({ ...values, ...{ event: this.props.match.params.event } })
                            .then(() => this.success(values))
                            .catch(error => this.error(error))
                    }
                }}
                formErrors={this.state.form_errors}
                fields={PeriodTextFormComponent.fields(this.state.settings, this)}
            />
        );
    }
}

export default withRouter(EventFormComponent);