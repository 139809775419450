import moment from "moment";
import * as Yup from "yup"
import EventPeriodContent from "./EventPeriodContent";
import { PriceHelper } from "../../helpers";

export default class EventPeriod {
    event;

    id;
    active;
    date_start;
    date_end;
    bookable_from;
    bookable_till;
    nr_days;
    price;
    maximum_participants;
    waiting_list;

    nr_registrations = 0;
    contents = [];

    constructor() {
        this.content = new EventPeriodContent();
    }

    static schema = (settings, model = null) => {
        let fields = {
            title: Yup.string().required('CMS.Event.Content.Form.title').max(100, 'CMS.Event.Content.Form.title|100|max'),
        }

        if (settings.show_start_date) {
            if (!model.date_start) {
                fields.date_start = Yup.mixed().required('CMS.Event.Content.Form.date_start');
            } else if (!model || !model.id) {
                fields.date_start = Yup.date().nullable().min(new Date((new Date()).setHours(0, 0, 0, 0)), 'CMS.Event.Content.Form.date_start|min_date');
            }
        }
        if (settings.show_end_date) {
            if (!model.date_end) {
                fields.date_end = Yup.mixed().required('CMS.Event.Content.Form.date_end')
            } else {
                fields.date_end = Yup.date().required('CMS.Event.Content.Form.date_end')
                    .when('date_start', (date_start, schema) => date_start ? schema.min(date_start, 'CMS.Event.Content.Form.date_end|CMS.Event.Content.Form.date_start|min_conditional') : schema);
            }

            let maxDays = model.calculateNrDays();
            fields.nr_days = Yup.number().required('CMS.Event.Content.Form.nr_days').typeError('CMS.Event.Content.Form.nr_days|number').min(1, 'CMS.Event.Content.Form.nr_days|1|number_min').max(maxDays, 'CMS.Event.Content.Form.nr_days|' + maxDays + '|number_max');
        }

        if (model.date_start) {
            fields.bookable_from = Yup.date()
                .when('date_start', (date_start, schema) => date_start ? schema.max(date_start, 'CMS.Event.Content.Form.bookable_from|CMS.Event.Content.Form.date_start|max_conditional') : schema)
                .nullable();

            fields.bookable_till = Yup.date()
                .when('date_start', (date_start, schema) => date_start ? schema.max(date_start, 'CMS.Event.Content.Form.bookable_till|CMS.Event.Content.Form.date_start|max_conditional') : schema)
                .when('bookable_from', (bookable_from, schema) => bookable_from ? schema.min(bookable_from, 'CMS.Event.Content.Form.bookable_till|CMS.Event.Content.Form.bookable_from|min_conditional') : schema)
                .nullable();
        }
        if (settings.show_rate) {
            fields.price = Yup.number().transform((_, value) => parseFloat(value.replace(/,/g, ''))).typeError('CMS.Event.Content.Form.price|number').required('CMS.Event.Content.Form.price');
        }

        return Yup.object(fields);
    }

    static fromJson = (json) => {
        let item = new EventPeriod();

        item.event = json.event;

        item.id = json.id;
        item.active = json.active;
        item.date_start = json.date_start ? moment(json.date_start) : null;
        item.date_end = json.date_end ? moment(json.date_end) : null;
        item.bookable_from = json.bookable_from ? moment(json.bookable_from) : null;
        item.bookable_till = json.bookable_till ? moment(json.bookable_till) : null;
        item.nr_days = json.nr_days;
        item.price = PriceHelper.price(json.price);

        item.nr_registrations = json.nr_registrations;
        item.contents = json.contents.map(content => EventPeriodContent.fromJson(content));

        return item;
    }

    calculateNrDays = () => {
        if (!this.date_start && !this.date_end) return 0;

        let start = moment(this.date_start).set({ hour: 0, minute: 0, second: 0 });
        let end = moment(this.date_end).set({ hour: 0, minute: 0, second: 0 });

        return end.diff(start, 'days') + 1;
    }

    clone = () => {
        this.id = null;
        this.date_start = null;
        this.date_end = null;
        this.nr_days = null;
        this.bookable_from = null;
        this.bookable_till = null;
    }

    registrationOpen = () => {
        let now = moment();
        if (this.bookable_from && now < this.bookable_from) {
            return false;
        }

        if (this.bookable_till && now >= this.bookable_till) {
            return false;
        }

        return true;
    }

    transform = (settings) => {
        let data = {
            date_start: this.date_start,
            date_end: this.date_end,
            bookable_from: this.bookable_from,
            bookable_till: this.bookable_till
        };

        let format = settings.show_hours ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD'

        if (typeof this.date_start === 'object') {
            data.date_start = this.date_start.format(format);
        }
        if (typeof this.date_end === 'object') {
            data.date_end = this.date_end.format(format);
        }

        if (this.bookable_from && typeof this.bookable_from === 'object') {
            data.bookable_from = this.bookable_from.format('YYYY-MM-DD HH:mm:ss');
        }
        if (this.bookable_till && typeof this.bookable_till === 'object') {
            data.bookable_till = this.bookable_till.format('YYYY-MM-DD HH:mm:ss');
        }

        return data;
    }
}
